import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import mpses from "../../../static/img/biblioteca/mps-es.jpg"
import covid from "../../../static/img/biblioteca/covid.jpg"
import lecciones_comunicacion from "../../../static/img/biblioteca/lecciones_comunicacion.jpg"
import uno from "../../../static/img/biblioteca/1.jpg"
import tesla from "../../../static/img/biblioteca/tesla.jpg"
import mifid from "../../../static/img/biblioteca/mifid.jpg"
import informes from "../../../static/img/biblioteca/informes.jpg"
import bursatilidad from "../../../static/img/biblioteca/bursatilidad.jpg"
import bursatilidad2 from "../../../static/img/biblioteca/bursatilidad2.jpg"
import comunicados from "../../../static/img/biblioteca/comunicados.jpg"
import ISR from "../../../static/img/biblioteca/ISR.jpg"
import ofertas_pubicas from "../../../static/img/biblioteca/ofertas_pubicas.jpg"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="RI Library"/>
            <Banner title="RI Library"/>
            <section className="executive_area pb-5">
                <div className="container">
                    <div className="row about_info index">
                        <div className="col-lg-4 col-sm-6 wow fadeInUp">
                            <a target="_blank"
                               href="https://investorcloud.s3.amazonaws.com/irstrat/documents/wp/MPS-2020.pdf">
                                <div className="about_consult_item">
                                    <div className="about_img">
                                        <img alt={" "} className="w-100" src={mpses}/>
                                    </div>
                                    <p className="p-3 text-center">
                                    Macro Perception Study: Market outlook for Mexico's economic situation 2020

                                   </p>
                               </div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-sm-6 wow fadeInUp">
                            <a target="_blank"
                               href="https://investorcloud.s3.amazonaws.com/irstrat/documents/wp/WP-COVID-19.pdf">
                                <div className="about_consult_item">
                                    <div className="about_img">
                                        <img alt={" "} className="w-100" src={covid}/>
                                    </div>
                                    <p className="p-3 text-center">
                                        Crisis management: COVID-19
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-sm-6 wow fadeInUp">
                            <a target="_blank"
                               href="https://investorcloud.s3.amazonaws.com/irstrat/documents/wp/Lecciones-crisis-financiera.pdf">
                                <div className="about_consult_item">
                                    <div className="about_img">
                                        <img alt={" "} className="w-100"
                                             src={lecciones_comunicacion}/>
                                    </div>
                                    <p className="p-3 text-center">
                                        Lessons in corporate communication from the financial crisis of 2007-09
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-sm-6 wow fadeInUp">
                            <a target="_blank"
                               href="https://investorcloud.s3.amazonaws.com/irstrat/documents/wp/WP-ESG.pdf">
                                <div className="about_consult_item">
                                    <div className="about_img">
                                        <img alt={" "} className="w-100" src={uno}/>
                                    </div>
                                    <p className="p-3 text-center">
                                        The growing opportunity for reporting on environmental, social and governance performance
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-sm-6 wow fadeInUp">
                            <a target="_blank"
                               href="https://s3.amazonaws.com/investorcloud/irstrat/documents/wp/Tesla-WP.pdf">
                                <div className="about_consult_item">
                                    <div className="about_img">
                                        <img alt={" "} className="w-100" src={tesla}/>
                                    </div>
                                    <p className="p-3 text-center">
                                        Investor Relations as part of the corporate growth strategy
                                        <br/>
                                        Tesla Case Review
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-sm-6 wow fadeInUp">
                            <a target="_blank"
                               href="https://s3.amazonaws.com/investorcloud/irstrat/documents/wp/MiFID-II.PDF">
                                <div className="about_consult_item">
                                    <div className="about_img">
                                        <img alt={" "} className="w-100" src={mifid}/>
                                    </div>
                                    <p className="p-3 text-center">
                                        MiFID II
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-sm-6 wow fadeInUp">
                            <a target="_blank"
                               href="http://cdn.investorcloud.net/Vert/WP-Informe-Anual.pdf">
                                <div className="about_consult_item">
                                    <div className="about_img">
                                        <img alt={" "} className="w-100" src={informes}/>
                                    </div>
                                    <p className="p-3 text-center">
                                        Annual Reports
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-sm-6 wow fadeInUp">
                            <a target="_blank"
                               href="https://s3.amazonaws.com/investorcloud/irstrat/documents/wp/I%CC%81ndice+de+Bursatilidad+I.pdf">
                                <div className="about_consult_item">
                                    <div className="about_img">
                                        <img alt={" "} className="w-100" src={bursatilidad}/>
                                    </div>
                                    <p className="p-3 text-center">
                                        The Bursatility Index <br/>
                                        <small>(PART 1)</small>
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-sm-6 wow fadeInUp">
                            <a target="_blank"
                               href="https://s3.amazonaws.com/investorcloud/irstrat/documents/wp/I%CC%81ndice+de+Bursatilidad+II.pdf">
                                <div className="about_consult_item">
                                    <div className="about_img">
                                        <img alt={" "} className="w-100"
                                             src={bursatilidad2}/>
                                    </div>
                                    <p className="p-3 text-center">
                                        The Bursatility Index <br/>
                                        <small>(PART 2)</small>
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-sm-6 wow fadeInUp">
                            <a target="_blank"
                               href="https://s3.amazonaws.com/investorcloud/irstrat/documents/wp/comunicados.pdf">
                                <div className="about_consult_item">
                                    <div className="about_img">
                                        <img alt={" "} className="w-100" src={comunicados}/>
                                    </div>
                                    <p className="p-3 text-center">
                                        Optimizing communications
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-sm-6 wow fadeInUp">
                            <a target="_blank"
                               href="https://s3.amazonaws.com/investorcloud/irstrat/documents/wp/ISR.pdf">
                                <div className="about_consult_item">
                                    <div className="about_img">
                                        <img alt={" "} className="w-100" src={ISR}/>
                                    </div>
                                    <p className="p-3 text-center">
                                        Sustainable investments
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-sm-6 wow fadeInUp">
                            <a target="_blank"
                               href="http://cdn.investorcloud.net/irstrat/documents/wp/IPO.pdf">
                                <div className="about_consult_item">
                                    <div className="about_img">
                                        <img alt={" "} className="w-100"
                                             src={ofertas_pubicas}/>
                                    </div>
                                    <p className="p-3 text-center">
                                        IPOs in Mexico
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
